<template lang="pug">
	.checkboxRadioGroup
		.checkboxRadio(v-for="item, index in contents.options")
			input(:type="contents.type" :id="item.value", :value="item.value" :name="contents.name" @change="handleChange($event.target.value, $event.target.checked)")
			label(:for="item.value") {{ item.label }}
			span obrigatorio
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "component-checkbox-radio",
	props: {
		...props,
		value: {
			type: [String, Array],
		}
	},
	computed: {
		checkeds: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		handleChange(value, isChecked) {
			if (this.contents.type == `radio`)
				this.checkeds = value
			else if (isChecked) {
				this.checkeds = [ ...this.checkeds, value ]
			} else {
				const index = this.checkeds.findIndex(item => item === value)
				if (index > -1)
					this.checkeds.splice(index, 1)
			}
		}
	},
}
</script>

<style lang="stylus" scoped src="./CheckboxRadio.styl"></style>
